.App {
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.profileWrapper {
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 50%;
  height: 60%;
  margin-bottom: 100px;
  margin-top: 100px;
  background-color: #d3d3d3;
  padding-bottom: 50px;
  border-radius: 10%;
}
.profileWrapper img {
  padding-top: 35px;
  height: 300px;
  padding-bottom: 30px;
  margin: auto;
}
#profileNumber {
  text-align: right;
}
.paginationWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  background-color: #d3d3d3;
}
.paginateBtn {
  padding: 15px;
  cursor: pointer !important;
}
.beforeAfterBtn {
  align-self: center;
  padding: 15px;
  text-transform: uppercase;
  cursor: pointer !important;
}
.beforeAfterBtn:hover,
.paginateBtn:hover {
  text-decoration: underline !important;
}
.displayNone {
  display: none !important;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: #d3d3d3;
}
.searchWrapper {
  width: 50%;
  margin: auto;
}
#currentPage {
  text-decoration: underline;
  color: #a0497b;
}
.editProfileBtn {
  background-color: #0a497b;
  color: white;
  align-self: center;
  padding: 15px;
  text-transform: uppercase;
  cursor: pointer !important;
  font-size: 17px;
}
.editFields {
  padding: 20px;
}
input,
textarea {
  font-size: 17px;
  padding: 15px;
}
.row {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}
.row button {
  margin-right: 25px;
}
#modelType, .loading {
  text-align: center;
  text-transform: capitalize;
}
.loading {
  margin-top: 24vh;
}
.imageRow {
  overflow-x: scroll !important;
}
.imageCol {
  min-width: 750px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}
#newProfileBtn {
  display: flex;
  margin: auto;
  justify-content: center;
}
#deleteBtn {
  background-color: #cc3300 !important;
}
